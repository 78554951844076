import { AppRoutesEnum } from '@core/models/appRoutes.enum';

export enum UserRolesEnumExist {
	CUSTOMER_BILLING = 'CUSTOMER_BILLING',
	AGENT_BILLING = 'AGENT_BILLING',
	LAST_MILE = 'LAST_MILE',
	LAST_MILE_TEAM = 'LAST_MILE_TEAM',
	MARKETPLACE = 'MARKETPLACE',
	MARKETPLACE_API = 'MARKETPLACE_API',
	USER_LIST = 'USER_LIST',
	ROLE_LIST = 'ROLE_LIST',
	CLIENT_VIEW_ORDER = 'CLIENT_VIEW_ORDER',
	CLIENT_EXPRESS_VIEW = 'CLIENT_EXPRESS_VIEW',
	CLIENT_VIEW_EXPRESS = 'CLIENT_VIEW_EXPRESS',
	CLIENT_VIEW = 'CLIENT_VIEW',
	DISPATCHER = 'DISPATCHER',
	INTERIM = 'INTERIM',
	ORDER_LIST = 'ORDER_LIST',
	REFERRALS_VIEW = 'REFERRALS_VIEW',
	MERCHANT = 'MERCHANT',
	COURIER = 'COURIER',
	COURIER_EXPRESS = 'COURIER_EXPRESS',
	WORKFORCE = 'WORKFORCE',
	API_USER = 'API_USER',
	CALL_CENTER_ORDERS = 'CALL_CENTER_ORDERS',
	CALL_CENTER_SCREEN = 'CALL_CENTER_SCREEN',
	MERCHANT_WAREHOUSE = 'MERCHANT_WAREHOUSE',
	INVENTORY_LIST = 'INVENTORY_LIST',
	INVENTORY_EDIT_ZONE = 'INVENTORY_EDIT_ZONE',
	INVENTORY_EDIT_QUANTITY = 'INVENTORY_EDIT_QUANTITY',
	INVENTORY_EDIT_PRICE = 'INVENTORY_EDIT_PRICE',
	INVENTORY_EDIT_PUBLISH = 'INVENTORY_EDIT_PUBLISH',
	INVENTORY_EDIT_THRESHOLD = 'INVENTORY_EDIT_THRESHOLD',
	TICKET_LIST = 'TICKET_LIST',
	TICKET_EDIT = 'TICKET_EDIT',
	CUSTOMER_LIST = 'CUSTOMER_LIST',
	CLIENT_VIEW_PROJECT = 'CLIENT_VIEW_PROJECT',
	CLIENT_VIEW_CUSTOMER = 'CLIENT_VIEW_CUSTOMER',
	TEAM_LIST = 'TEAM_LIST',
	ALERT_LISTING = 'ALERT_LISTING',
	TICKET_CONFIG_EDIT = 'TICKET_CONFIG_EDIT',
	RATING_LIST = 'RATING_LIST',
	TAGS_LIST = 'TAGS_LIST',
	PORTAL_USER = 'PORTAL_USER',
	RATINGS_VIEW = 'RATINGS_VIEW',
  UPDATE_TICKET_EMAIL = 'UPDATE_TICKET_EMAIL',
  ORDER_PRODUCT_QUANTITY = 'ORDER_PRODUCT_QUANTITY'
}
export enum MerchantsRolesEnum {
	MERCHANT_LIST_VIEW = 'MERCHANT_LIST_VIEW',
	MERCHANT_VIEW = 'MERCHANT_VIEW',
	MERCHANT_EDIT = 'MERCHANT_EDIT',
	MERCHANT_ADD_REMOVE = 'MERCHANT_ADD_REMOVE',
	MERCHANT_ARCHIVE = 'MERCHANT_ARCHIVE',
	MERCHANT_CLOSE_ALL = 'MERCHANT_CLOSE_ALL',

	MERCHANT_INFORMATION_VIEW = 'MERCHANT_INFORMATION_VIEW',
	MERCHANT_INFORMATION_CONTACT_EDIT = 'MERCHANT_INFORMATION_CONTACT_EDIT',
	MERCHANT_INFORMATION_CONTACT_ADD_REMOVE = 'MERCHANT_INFORMATION_CONTACT_ADD_REMOVE',
	MERCHANT_INFORMATION_CONTACT_REGISTER_EMAIL = 'MERCHANT_INFORMATION_CONTACT_REGISTER_EMAIL',

	MERCHANT_PRODUCT_LIST_VIEW = 'MERCHANT_PRODUCT_LIST_VIEW',
	MERCHANT_PRODUCT_VIEW = 'MERCHANT_PRODUCT_VIEW',
	MERCHANT_PRODUCT_EDIT = 'MERCHANT_PRODUCT_EDIT',
	MERCHANT_PRODUCT_ADD_REMOVE = 'MERCHANT_PRODUCT_ADD_REMOVE',
	MERCHANT_PRODUCT_DISCOUNT_ADD = 'MERCHANT_PRODUCT_DISCOUNT_ADD',
	MERCHANT_PRODUCT_RECOMMEND = 'MERCHANT_PRODUCT_RECOMMEND',
	MERCHANT_PRODUCT_PUBLISH = 'MERCHANT_PRODUCT_PUBLISH',
	MERCHANT_PRODUCT_VAT = 'MERCHANT_PRODUCT_VAT',
	MERCHANT_PRODUCT_RATING = 'MERCHANT_PRODUCT_RATING',
	MERCHANT_PRODUCT_CUSTOMIZATIONS = 'MERCHANT_PRODUCT_CUSTOMIZATIONS',
	MERCHANT_PRODUCT_CATEGORIES = 'MERCHANT_PRODUCT_CATEGORIES',
	MERCHANT_PRODUCT_BULK_PRODUCTS_ADD = 'MERCHANT_PRODUCT_BULK_PRODUCTS_ADD',
	MERCHANT_PRODUCT_BULK_ADD_TO_COLLECTIONS = 'MERCHANT_PRODUCT_BULK_ADD_TO_COLLECTIONS',
	MERCHANT_PRODUCT_EXPORT_ALL = 'MERCHANT_PRODUCT_EXPORT_ALL',

	MERCHANT_COLLECTIONS_VIEW = 'MERCHANT_COLLECTIONS_VIEW',
	MERCHANT_COLLECTION_EDIT = 'MERCHANT_COLLECTION_EDIT',
	MERCHANT_COLLECTION_ADD_REMOVE = 'MERCHANT_COLLECTION_ADD_REMOVE',
	MERCHANT_COLLECTION_ACTIVATE = 'MERCHANT_COLLECTION_ACTIVATE',

	MERCHANT_STORES_VIEW = 'MERCHANT_STORES_VIEW',
	MERCHANT_STORE_EDIT = 'MERCHANT_STORE_EDIT',
	MERCHANT_STORE_ADD_REMOVE = 'MERCHANT_STORE_ADD_REMOVE',
	MERCHANT_STORE_ACTIVATE = 'MERCHANT_STORE_ACTIVATE',
	MERCHANT_STORE_INFORMATION_VIEW = 'MERCHANT_STORE_INFORMATION_VIEW',
	MERCHANT_STORE_INFORMATION_APP = 'MERCHANT_STORE_INFORMATION_APP', // Publish on APP
	MERCHANT_STORE_INFORMATION_STORE = 'MERCHANT_STORE_INFORMATION_STORE', // Store Open/Close
	MERCHANT_STORE_INFORMATION_STORE_CAPLIMIT = 'MERCHANT_STORE_INFORMATION_STORE_CAPLIMIT', // Store Caplimit
	MERCHANT_STORE_COLLECTIONS_VIEW = 'MERCHANT_STORE_COLLECTIONS_VIEW',
	MERCHANT_STORE_CAPLIMIT_VIEW = 'MERCHANT_STORE_CAPLIMIT_VIEW',
	MERCHANT_STORE_ADVANCED_VIEW = 'MERCHANT_STORE_ADVANCED_VIEW',
	MERCHANT_STORE_CLOSING_DATA_VIEW = 'MERCHANT_STORE_CLOSING_DATA_VIEW',

	MERCHANT_BRANDS_VIEW = 'MERCHANT_BRANDS_VIEW',
	MERCHANT_BRAND_EDIT = 'MERCHANT_BRAND_EDIT',
	MERCHANT_BRAND_ADD_REMOVE = 'MERCHANT_BRAND_ADD_REMOVE',

	MERCHANT_ADVANCED_VIEW = 'MERCHANT_ADVANCED_VIEW',
	MERCHANT_ADVANCED_COMMISSION = 'MERCHANT_ADVANCED_COMMISSION',
	MERCHANT_ADVANCED_PRODUCT_VAT = 'MERCHANT_ADVANCED_PRODUCT_VAT',
	MERCHANT_ADVANCED_POPULAR_SEARCHES = 'MERCHANT_ADVANCED_POPULAR_SEARCHES',
	MERCHANT_ADVANCED_IMPULSE_ITEM = 'MERCHANT_ADVANCED_IMPULSE_ITEM',

	MERCHANT_FEEDBACK_VIEW = 'MERCHANT_FEEDBACK_VIEW',
	MERCHANT_FEEDBACK_REMOVE = 'MERCHANT_FEEDBACK_REMOVE'
}
export enum HomePageItemRolesEnum {
	HOME_PAGES_VIEW = 'HOME_PAGES_VIEW',
	HOME_PAGE_ADD_REMOVE = 'HOME_PAGE_ADD_REMOVE',
	HOME_PAGE_SECTIONS_VIEW = 'HOME_PAGE_SECTIONS_VIEW',
	HOME_PAGE_SECTION_ACTIVATE = 'HOME_PAGE_SECTION_ACTIVATE',
	HOME_PAGE_SECTION_ADD_REMOVE = 'HOME_PAGE_SECTION_ADD_REMOVE',
	HOME_PAGE_SECTION_EDIT = 'HOME_PAGE_SECTION_EDIT'
}
export enum TagsRolesEnum {
	TAGS_PRODUCTS_VIEW = 'TAGS_PRODUCTS_VIEW',
	TAGS_STORES_VIEW = 'TAGS_STORES_VIEW',
	TAGS_PRODUCT_VIEW = 'TAGS_PRODUCT_VIEW',
	TAGS_PRODUCT_EDIT = 'TAGS_PRODUCT_EDIT',
	TAGS_PRODUCT_ADD_REMOVE = 'TAGS_PRODUCT_ADD_REMOVE',
	TAGS_PRODUCT_RELATIONS = 'TAGS_PRODUCT_RELATIONS',
	TAGS_STORE_EDIT = 'TAGS_STORE_EDIT',
	TAGS_STORE_ADD_REMOVE = 'TAGS_STORE_ADD_REMOVE'
}
export enum AreaRolesEnum {
	AREA_VIEW = 'AREA_VIEW',
	AREA_ADD_REMOVE = 'AREA_ADD_REMOVE',
	AREA_EDIT = 'AREA_EDIT'
}
export enum AlertsRolesEnum {
	ALERTS = 'ALERTS',
	ALERT_EDIT = 'ALERT_EDIT',
	ALERT_ACTIVATE = 'ALERT_ACTIVATE',
	ALERT_ADD_REMOVE = 'ALERT_ADD_REMOVE'
}
export enum ReportsRolesEnum {
	REPORT_APP_ORDERS_VIEW = 'REPORT_APP_ORDERS_VIEW',
	REPORT_REFERRAL_VIEW = 'REPORT_REFERRAL_VIEW'
}
export enum ClientViewCustomerRolesEnum {
	CUSTOMERS = 'CUSTOMERS',
	CUSTOMER_VIEW = 'CUSTOMER_VIEW',
	CUSTOMER_EDIT = 'CUSTOMER_EDIT',
	CUSTOMERS_EXPORT_ALL = 'CUSTOMERS_EXPORT_ALL',
	CUSTOMER_MANAGE_WALLET = 'CUSTOMER_MANAGE_WALLET',
	CUSTOMER_WALLET_HISTORY = 'CUSTOMER_WALLET_HISTORY',
	CUSTOMER_BLOCK_USER_VIEW = 'CUSTOMER_BLOCK_USER_VIEW',
	CUSTOMER_BLOCK_USER_EDIT = 'CUSTOMER_BLOCK_USER_EDIT',
	CUSTOMER_BLOCK_USER_ADD_REMOVE = 'CUSTOMER_BLOCK_USER_ADD_REMOVE'
}
export enum PromotionsRolesEnum {
	PROMOTIONS_PROMO_LIST_VIEW = 'PROMOTIONS_PROMO_LIST_VIEW',
	PROMOTIONS_CAMPAIGN_LIST_VIEW = 'PROMOTIONS_CAMPAIGN_LIST_VIEW',
	PROMOTIONS_BANNER_LIST_VIEW = 'PROMOTIONS_BANNER_LIST_VIEW',
	PROMOTIONS_CASHBACK_LIST_VIEW = 'PROMOTIONS_CASHBACK_LIST_VIEW',

	PROMOTIONS_PROMO_VIEW = 'PROMOTIONS_PROMO_VIEW',
	PROMOTIONS_PROMO_EDIT = 'PROMOTIONS_PROMO_EDIT',
	PROMOTIONS_PROMO_ACTIVATE = 'PROMOTIONS_PROMO_ACTIVATE',
	PROMOTIONS_PROMO_ADD_REMOVE = 'PROMOTIONS_PROMO_ADD_REMOVE',

	PROMOTIONS_CAMPAIGN_VIEW = 'PROMOTIONS_CAMPAIGN_VIEW',
	PROMOTIONS_CAMPAIGN_EDIT = 'PROMOTIONS_CAMPAIGN_EDIT',
	PROMOTIONS_CAMPAIGN_ADD_REMOVE = 'PROMOTIONS_CAMPAIGN_ADD_REMOVE',
	PROMOTIONS_CAMPAIGN_ACTIVATE = 'PROMOTIONS_CAMPAIGN_ACTIVATE',

	PROMOTIONS_BANNER_VIEW = 'PROMOTIONS_BANNER_VIEW',
	PROMOTIONS_BANNER_EDIT = 'PROMOTIONS_BANNER_EDIT',
	PROMOTIONS_BANNER_ADD_REMOVE = 'PROMOTIONS_BANNER_ADD_REMOVE',
	PROMOTIONS_BANNER_ACTIVATE = 'PROMOTIONS_BANNER_ACTIVATE',

	PROMOTIONS_CASHBACK_VIEW = 'PROMOTIONS_CASHBACK_VIEW',
	PROMOTIONS_CASHBACK_EDIT = 'PROMOTIONS_CASHBACK_EDIT',
	PROMOTIONS_CASHBACK_ADD_REMOVE = 'PROMOTIONS_CASHBACK_ADD_REMOVE',
	PROMOTIONS_CASHBACK_ACTIVATE = 'PROMOTIONS_CASHBACK_ACTIVATE'
}

export enum ConfigurationEnum {
  CONFIGURATION_VIEW = 'CONFIGURATION_VIEW',
  CONFIGURATION_EDIT = 'CONFIGURATION_EDIT',
  CONFIGURATION_DELETE = 'CONFIGURATION_DELETE',
  CONFIGURATION_DRIVER_ICON_VIEW = 'CONFIGURATION_DRIVER_ICON_VIEW',
  CONFIGURATION_DRIVER_ICON_EDIT = 'CONFIGURATION_DRIVER_ICON_EDIT',
  CONFIGURATION_DRIVER_ICON_DELETE = 'CONFIGURATION_DRIVER_ICON_DELETE',
  CONFIGURATION_ONLINE_SURCHARGE_VIEW = "CONFIGURATION_ONLINE_SURCHARGE_VIEW",
  CONFIGURATION_ONLINE_SURCHARGE_EDIT = "CONFIGURATION_ONLINE_SURCHARGE_EDIT",
};

export enum ReportEnum {
  REFERRAL_AND_PROMO_CODE_REPORT = 'REFERRAL_AND_PROMO_CODE_REPORT',
  CONSUMED_SCANNED_BAG_REPORT = 'CONSUMED_SCANNED_BAG_REPORT',
  REPORT_LIST = 'REPORT_LIST',
};

export const UserRolesEnum = <const>{
  ...UserRolesEnumExist,
  ...MerchantsRolesEnum,
  ...HomePageItemRolesEnum,
  ...TagsRolesEnum,
  ...AreaRolesEnum,
  ...AlertsRolesEnum,
  ...ReportsRolesEnum,
  ...ClientViewCustomerRolesEnum,
  ...PromotionsRolesEnum,
  ...ConfigurationEnum,
  ...ReportEnum

};

export const RedirectRoutes = {
	CLIENT_VIEW_ORDER: {
		url: AppRoutesEnum.CLIENT_VIEW_DASHBOARD_URL,
		redirectPriority: 1
	},
	DISPATCHER: { url: AppRoutesEnum.DISPATCHER_TEAMS_URL, redirectPriority: 2 },
	CUSTOMER_BILLING: {
		url: AppRoutesEnum.BILLING_CUSTOMER_URL,
		redirectPriority: 3
	},
	AGENT_BILLING: { url: AppRoutesEnum.BILLING_AGENT_URL, redirectPriority: 4 },
	MERCHANT: {
		url: AppRoutesEnum.MARKETPLACE_WAREHOUSE_DASHBOARD_URL,
		redirectPriority: 5
	},
	MERCHANT_WAREHOUSE: {
		url: AppRoutesEnum.MARKETPLACE_WAREHOUSE_DASHBOARD_URL,
		redirectPriority: 6
	},
	INTERIM: { url: AppRoutesEnum.INTERIM_ORDERS_URL, redirectPriority: 7 },
	CLIENT_EXPRESS_VIEW: {
		url: AppRoutesEnum.CLIENT_EXPRESS_VIEW_BRANCHES_URL,
		redirectPriority: 8
	},
	MARKETPLACE: {
		url: AppRoutesEnum.MARKETPLACE_ORDERS_URL,
		redirectPriority: 9
	},
	COURIER: {
		url: AppRoutesEnum.COURIER_INCOMING_ORDERS_URL,
		redirectPriorty: 10
	},
	ORDER_LIST: {
		url: AppRoutesEnum.MARKETPLACE_ORDERS_URL,
		redirectPriority: 11
	},
	TICKET_LIST: {
		url: AppRoutesEnum.MARKETPLACE_INCIDENTS_URL,
		redirectPriority: 12
	},
	INVENTORY_LIST: {
		url: AppRoutesEnum.MARKETPLACE_INVENTORY_URL,
		redirectPriority: 13
	},
	REFERRALS_VIEW: {
		url: AppRoutesEnum.MARKETPLACE_REFERRALS_OVERVIEW_URL,
		redirectPriority: 14
	},
	USER_LIST: { url: AppRoutesEnum.MARKETPLACE_USERS_URL, redirectPriority: 15 },
	ROLE_LIST: { url: AppRoutesEnum.MARKETPLACE_ROLES_URL, redirectPriority: 16 },
	INCIDENT_CONFIGURATION_LIST: {
		url: AppRoutesEnum.MARKETPLACE_INCIDENT_CONFIG_URL,
		redirectPriorty: 17
	},
	PORTAL_USER: {
		url: AppRoutesEnum.MARKETPLACE_INCIDENT_CONFIG_URL,
		redirectPriorty: 18
	}
};
