import { Routes } from '@angular/router';
// Layouts
import { CommonLayoutComponent } from './common/common-layout.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthenticationLayoutComponent } from './common/authentication-layout.component';
import { AuthenticationGuard } from '@core/guards/authentication.guard';
import { AuthenticateURLGaurd } from '@core/guards/authenticateURL.gaurd';
import { PageNotFoundComponent } from '@app/common/page-not-found.component';

export const AppRoutes: Routes = [
	{
		path: '',
		// canActivate: [AuthenticationGuard],
		redirectTo: 'portal/login',
		pathMatch: 'full'
	},
	{
		path: '',
		component: CommonLayoutComponent,
		canActivate: [AuthenticationGuard],
		canActivateChild: [NgxPermissionsGuard],
		children: [
			{
				path: 'courier',
				loadChildren: () =>
					import('./courier/courier.module').then((m) => m.CourierModule)
			},
			{
				path: 'workforce',
				loadChildren: () =>
					import('./workforce/workforce.module').then((m) => m.WorkForceModule)
			},
			{
				path: 'dashboard',
				loadChildren: () =>
					import('./outsource/outsource.module').then((m) => m.OutSourceModule)
			},
			{
				path: 'clientExpressView',
				loadChildren: () =>
					import('./client-express-view/client-express-view.module').then(
						(m) => m.ClientExpressViewModule
					)
			},
			{
				path: 'clientview',
				loadChildren: () =>
					import('./client-view/client-view.module').then(
						(m) => m.ClientViewModule
					)
			},

			{
				path: 'marketplace',
				loadChildren: () =>
					import('./marketplace/marketplace.module').then(
						(m) => m.MarketplaceModule
					)
			},
			{
				path: 'eddress-book',
				loadChildren: () =>
					import('./eddress-book/eddress-book.module').then(
						(m) => m.EddressBookModule
					)
			},
			{
				path: 'dispatcher',
				// canActivate: [AuthenticateURLGaurd],
				// data: { role: 'DISPATCHER' },
				loadChildren: () =>
					import('./dispatcher/dispatcher.module').then(
						(m) => m.DispatcherModule
					)
			},
			{
				path: 'billing',
				loadChildren: () =>
					import('./billing/billing.module').then((m) => m.BillingModule)
			},
			{
				path: 'admin-settings',
				canActivate: [AuthenticateURLGaurd],
				data: { role: 'DISPATCHER' },
				loadChildren: () =>
					import('./admin-settings/admin-settings.module').then(
						(m) => m.AdminSettingsModule
					)
			},
			{
				path: 'interim-orders',
				canActivate: [AuthenticateURLGaurd],
				data: { role: 'INTERIM' },
				loadChildren: () =>
					import('./interim-orders/interim-orders.module').then(
						(m) => m.InterimOrdersModule
					)
			},
			{
				path: 'last-mile',
				// canActivate: [AuthenticateURLGaurd],
				// data: { role: 'INTERIM' },
				loadChildren: () =>
					import('./last-mile/last-mile.module').then((m) => m.LastMileModule)
			}
		]
	},
	{
		path: '',
		component: AuthenticationLayoutComponent,
		children: [
			{
				path: 'portal',
				loadChildren: () =>
					import('./authentication/authentication.modules').then(
						(m) => m.AuthenticationModule
					)
			}
		]
	},
	{
		path: '',
		component: AuthenticationLayoutComponent,
		children: [
			{
				path: 'server-error',
				loadChildren: () =>
					import('./server-error/server-error.module').then(
						(m) => m.ServerErrorModule
					)
			}
		]
	},
	{
		path: '',
		component: AuthenticationLayoutComponent,
		children: [
			{
				path: 'access-denied',
				loadChildren: () =>
					import('./access-denied/access-denied.module').then(
						(m) => m.AccessDeniedModule
					)
			}
		]
	},
	{
		path: '**',
		component: PageNotFoundComponent
	}
];
