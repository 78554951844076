import { Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {
	ActivatedRoute,
	Router,
	NavigationEnd,
	ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '@core/services/auth.service';
import { NotificationService } from '@core/services/notification.service';
import { UIStateService } from '@core/services/uiState.service';
import { EddressFacadeService } from '@core/services/eddressFacade.service';
import { apiBaseUrl } from '@core/services/api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import * as $ from 'jquery';
import { Title } from '@angular/platform-browser';
import { User } from '@core/models/user.model';
import { faBiking, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { UserRolesEnum, RedirectRoutes } from '@core/models/userRoles.enum';
import { AppRoutesEnum } from '@core/models/appRoutes.enum';
export const mainPortalUrl = new InjectionToken<string>('mainPortalUrl');

@Component({
	selector: 'app-dashboard',
	templateUrl: './common-layout.component.html'
})
export class CommonLayoutComponent implements OnInit {
	faBiking = faBiking;
	faHeadset = faHeadset;
	public app: any;
	public headerThemes: any;
	public changeHeader: any;
	public sidenavThemes: any;
	public changeSidenav: any;
	public headerSelected: any;
	public sidenavSelected: any;
	// related to user object
	fullName = '';
	companyLogo = 'assets/images/user.jpg';
	companyName = '';
	userData: User;
	subDomain = '';
	pageTitle = '';
	isBackBtn = true;
	isMainPage = false;
	showBillingSection = false;
	showLastMileSection = false;
	showMarketingSection = false;
	showSupportSection = false;
	showStoreSection = false;
	showOrderSection = false;
	showSettingsSection = false;
	showCustomersSection = false;
	isLoggedIn: Observable<boolean>;
  isVip = false;
  showPromotionsSection = false;

	element: any;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private authService: AuthService,
		private notify: NotificationService,
		private uiStateService: UIStateService,
		private _location: Location,
		public eddress: EddressFacadeService,
		private permissionsService: NgxPermissionsService,
		private titleService: Title,
		@Inject(mainPortalUrl) private _mainPortalUrl: string
	) {
		if (localStorage.getItem('companyLogo') != null) {
			this.eddress.setCompanyLogo(localStorage.getItem('companyLogo'));
		}
		if (localStorage.getItem('logoIcon') != null) {
			this.eddress.setLogoIcon(localStorage.getItem('logoIcon'));
		}
		if (localStorage.getItem('favIcon') != null) {
			this.eddress.setAppFavicon(localStorage.getItem('favIcon'));
		}
		this.titleService.setTitle(localStorage.getItem('title'));
		const url = location.host;
		this.subDomain = url.split('.')[0];
		if (this.subDomain === 'portal' || this.subDomain === 'dash') {
			this.subDomain = url.split('.')[1];
		}
		this.app = {
			layout: {
				sidePanelOpen: false,
				isMenuOpened: true,
				isMenuCollapsed: false,
				themeConfigOpen: false,
				rtlActived: false
			}
		};

		this.headerThemes = [
			'header-default',
			'header-primary',
			'header-info',
			'header-success',
			'header-danger',
			'header-dark'
		];
		this.changeHeader = changeHeader;

		function changeHeader(headerTheme) {
			this.headerSelected = headerTheme;
		}

		this.sidenavThemes = ['sidenav-default', 'side-nav-dark'];
		this.changeSidenav = changeSidenav;

		function changeSidenav(sidenavTheme) {
			this.sidenavSelected = sidenavTheme;
		}
		this.authService.currentUser.subscribe((data) => {
			this.userData = data;
			this.fullName = data.fullName;
			this.companyLogo = data.companyLogo;
			this.companyName = data.companyName;
		});

		this.isLoggedIn = authService.isLoggedIn();
		// this.parentUrl = this.router.url
		// this.parentUrl = localStorage.getItem('parentUrl');
		// router.events.subscribe((event) => {
		// 	if (event instanceof NavigationEnd) {
		// 		this.currentUrl = event.url;
		// 	}
		// });
		this.uiStateService.isMenuCollapsed.subscribe((value) => {
			if (this.app.layout.isMenuCollapsed) {
				this.app.layout.isMenuCollapsed = value;
			}
		});
	}

	ngOnInit() {
		const url = this.router.routerState.snapshot.url.substring(0, 17);
		this.isMainPage = url === '/dispatcher/teams' || url === '/interim-orders';
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const navigatedUrl = this.router.routerState.snapshot.url.substring(
					0,
					17
				);
				this.isMainPage =
					navigatedUrl === '/dispatcher/teams' ||
					navigatedUrl === '/interim-orders';
			}
		});

		this.parseRoute(this.router.routerState.snapshot.root);
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.parseRoute(this.router.routerState.snapshot.root);
			}
		});
		this.parseRouteHeader(this.router.routerState.snapshot.root);
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.parseRouteHeader(this.router.routerState.snapshot.root);
			}
		});
		this.permissionsService.permissions$.subscribe((per) => {
			if (
				per.hasOwnProperty(UserRolesEnum.CUSTOMER_BILLING) ||
				per.hasOwnProperty(UserRolesEnum.AGENT_BILLING)
			) {
				this.showBillingSection = true;
			}
			if (
				per.hasOwnProperty(UserRolesEnum.LAST_MILE) ||
				per.hasOwnProperty(UserRolesEnum.LAST_MILE_TEAM)
			) {
				this.showLastMileSection = true;
			}
			if (per.hasOwnProperty(UserRolesEnum.MARKETPLACE)) {
				this.showMarketingSection = true;
			}
			if (per.hasOwnProperty(UserRolesEnum.TICKET_LIST)) {
				this.showSupportSection = true;
			}
      if (
        per.hasOwnProperty(UserRolesEnum.USER_LIST) ||
        per.hasOwnProperty(UserRolesEnum.ROLE_LIST) ||
        per.hasOwnProperty(UserRolesEnum.TICKET_CONFIG_EDIT)
      ) {
        this.showSettingsSection = true;
      }
			if (per.hasOwnProperty(UserRolesEnum.INVENTORY_LIST)) {
				this.showStoreSection = true;
			}
			if (per.hasOwnProperty(UserRolesEnum.CUSTOMER_LIST)) {
				this.showCustomersSection = true;
			}
			if (per.hasOwnProperty(UserRolesEnum.ORDER_LIST)) {
				this.showOrderSection = true;
			}
      if (per.hasOwnProperty(UserRolesEnum.PROMOTIONS_PROMO_LIST_VIEW)) {
				this.showPromotionsSection = true;
			}
		});
	}
    parseRoute(node: ActivatedRouteSnapshot) {
      if (node.data['title']) {
        this.pageTitle = node.data['title'];
        this.isVip = node.params.id ? JSON.parse(JSON.stringify(node.params.id)).isVip : false;
      } else {
			  this.pageTitle = '';
		}
		if (node.firstChild) {
			this.parseRoute(node.firstChild);
		}
	}
	parseRouteHeader(node: ActivatedRouteSnapshot) {
		if (node.data['backBtn'] === false) {
			this.isBackBtn = node.data['backBtn'];
		} else {
			this.isBackBtn = true;
		}
		if (node.firstChild) {
			this.parseRouteHeader(node.firstChild);
		}
	}
	mainPortal() {
		window.open(this._mainPortalUrl, '_blank');
	}
	logout() {
		this.authService.removeUserAuthentication();
		localStorage.clear();
		//this.notify.successNotification('Logout successfully');

		this.router.navigateByUrl(AppRoutesEnum.LOGIN_URL);
	}
	getCollapsed(value: boolean) {
		this.app.layout.isMenuCollapsed = value;
		this.uiStateService.updateMenuCollapsed(value);
	}
	back() {
		this._location.back();
	}
	goToMain() {
		this.permissionsService.permissions$.forEach((per) => {
			const redirectUrl = this.eddress.utilityService.getRedirectUrl(per);
			if (redirectUrl) {
				this.router.navigateByUrl(redirectUrl);
			}
		});
	}
}
