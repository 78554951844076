export const environment = {
	production: true,
	PUSHER_API_KEY: '6d18614f3162d08e0fdd',
	PUSHER_API_CLUSTER: 'eu',
	eddressAppConfig: {
		apiBaseUrl: 'https://noknok-staging-api.noknokgroceries.com/api/',
		graphqlBaseUrl: 'https://noknok-staging-api.noknokgroceries.com/graphql/',
		mainPortalUrl: 'https://old-portal.noknokgroceries.com/portal#!/'
	}
};
