import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.service';
import { User } from '../models/user.model';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { JwtDecodeService } from './jwtdecode.service';
import { LocalStorageService } from './localstorage.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private currentUserSubject = new BehaviorSubject<User>({} as User);
	public currentUser = this.currentUserSubject
		.asObservable()
		.pipe(distinctUntilChanged());

	public isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
	public isAuthenticated = this.isAuthenticatedSubject.asObservable();

	private loginUrl = 'portal/common/user/login';

	constructor(
		private router: Router,
		private api: ApiService,
		private jwtDecode: JwtDecodeService,
		private localStorage: LocalStorageService,
		private permissionsService: NgxPermissionsService
	) {}

	public registerUser(params, token): Observable<any> {
		params.token = token;
		return this.api.post('portal/common/user/signupUser', params).pipe(
			map((data) => {
				// this.setToken(data.token);
				// this.addRoles(data.token);
				// this.setUserAuthentication(data.user, this.addStoreId(data.token));
				return data;
			})
		);
	}
	attemptAuth(username: string, password: string): Observable<User> {
		const credentials = { userName: username, password: password };
		return this.api.post('portal/common/user/login', credentials).pipe(
			map((data) => {
				if (data.user) {
					data.user.idTenant = data.idTenant;
				}
				this.setToken(data.token);
				this.addRoles(data.token);

				this.setUserAuthentication(
					data.user,
					this.addStoreId(data.token),
					data.config
				);
				return data;
			})
		);
	}

	getBearerToken() {
		const token = this.localStorage.getItem('token');
		if (token) {
			return token;
		}
	}

	populateUser() {
		const token = this.getBearerToken();
		if (token) {
			this.setToken(token);
			this.addRoles(token);
			this.setUserAuthentication(this.getUserObject(), this.addStoreId(token));
		} else {
			this.removeUserAuthentication();
			// this.router.navigateByUrl('/portal/login');
		}
	}

	setUserAuthentication(
		user: User,
		storeId: string = null,
		config: string = null
	) {
		if (storeId) user.storeId = storeId;
		if (config) user.config = config;
		this.currentUserSubject.next(user);
		this.isAuthenticatedSubject.next(true);
		// this.isLoginAuthenticatedSubject.next(false);
		this.setUserObject(user);
	}

	removeUserAuthentication() {
		this.localStorage.clearItems();
		this.currentUserSubject.next({} as User);
		this.isAuthenticatedSubject.next(false);
		// this.isLoginAuthenticatedSubject.next(true);
	}

	setToken(token: String) {
		this.localStorage.setItem('token', token);
	}

	removeToken() {
		this.localStorage.removeItem('token');
	}

	setUserObject(user: User) {
		this.localStorage.setItem('user', user);
	}

	getUserObject(): User {
		return this.localStorage.getItem('user');
	}

	addRoles(token: string) {
		this.permissionsService.flushPermissions();
		const rolesString = JSON.stringify(
			this.jwtDecode.decodeToken(token)['tags']
		);
		if (rolesString) {
			const rolesArray = JSON.parse(rolesString);
			for (const role of rolesArray) {
				this.permissionsService.addPermission(role);
				this.permissionsService.addPermission(role);
			}
		}
	}

	addStoreId(token): string {
		return this.jwtDecode.decodeToken(token)['storeId'];
	}

	isLoggedIn(): Observable<boolean> {
		return this.isAuthenticated;
	}
}
