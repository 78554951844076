import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-dashboard',
	template:
		'<div class="authentication">\n' +
		'  <div class="page-404 container">\n' +
		'    <div class="row">\n' +
		'      <div class="col-md-6">\n' +
		'        <div class="full-height">\n' +
		'          <div class="vertical-align full-height pdd-horizon-70">\n' +
		'            <div class="table-cell">\n' +
		'              <h1 class="text-dark font-size-80 text-light">Oops!</h1>\n' +
		'              <p class="lead lh-1-8">Something went wrong. But don\'t worry,<br>we\'ll get you back on track!</p>\n' +
		'              <a href="" [routerLink]="[\'/\']" class="btn btn-warning">Get Me Back!</a>\n' +
		'            </div>\n' +
		'          </div>\n' +
		'        </div>\n' +
		'      </div>\n' +
		'      <div class="col-md-5 ml-auto hidden-sm hidden-xs">\n' +
		'        <div class="full-height height-100">\n' +
		'          <div class="vertical-align full-height">\n' +
		'            <div class="table-cell">\n' +
		'              <img class="img-responsive" src="assets/images/others/404.png" alt="">\n' +
		'            </div>\n' +
		'          </div>\n' +
		'        </div>\n' +
		'      </div>\n' +
		'    </div>\n' +
		'  </div>\n' +
		'</div>'
})
export class PageNotFoundComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
