export enum MarketPlaceRoutes {
	MARKETPLACE_ORDERS_URL = '/marketplace/orders',
	MARKETPLACE_ORDERS_VIEW_URL = '/marketplace/orders/order-view/',
	MARKETPLACE_ORDERS_EDIT_URL = '/marketplace/orders/edit-purchase-order/',
	MARKETPLACE_PROMO_CODE_URL = '/marketplace/promotions/promo-code',
	MARKETPLACE_PROMO_CODE_EDIT_URL = '/marketplace/promotions/edit-promocode/',
	MARKETPLACE_PROMO_CODE_ADD_URL = '/marketplace/promotions/edit-promocode',

	MARKETPLACE_CAMPAIGN_URL = '/marketplace/promotions/campaign',
	MARKETPLACE_BANNER_URL = '/marketplace/promotions/banner',
	MARKETPLACE_CASH_BACK_URL = '/marketplace/promotions/cash-back',

	MARKETPLACE_INCIDENTS_URL = '/marketplace/incidents',
	MARKETPLACE_INVENTORY_URL = '/marketplace/inventory',
	MARKETPLACE_INVENTORY_IMPORT_URL = '/marketplace/inventory/import-inventory',
	MARKETPLACE_REFERRALS_OVERVIEW_URL = '/marketplace/referrals-overview',
	MARKETPLACE_USERS_URL = '/marketplace/users',
	MARKETPLACE_ROLES_URL = '/marketplace/roles',
	MARKETPLACE_ROLES_PERMISSIONS_URL = '/marketplace/roles/permissions/',
	MARKETPLACE_WAREHOUSE_DASHBOARD_URL = '/marketplace/warehouse-dashboard',
	MARKETPLACE_ORDER_MAP_URL = '/marketplace/order/order-map',
	MARKETPLACE_CUSTOMERS_URL = '/marketplace/customers',
	MARKETPLACE_AREA_URL = '/marketplace/area-view',
	MARKETPLACE_EDIT_AREA_URL = '/marketplace/area-view/edit-area/',
	MARKETPLACE_NEW_AREA_URL = '/marketplace/area-view/new-area',
	MARKETPLACE_CUSTOMERS_VIEW_URL = '/marketplace/customers/customer-view/',
	MARKETPLACE_CUSTOMERS_BLACKLISTED_URL = '/marketplace/customers/blacklisted',
	MARKETPLACE_TEAMS_URL = '/marketplace/teams',
	MARKETPLACE_TEAMS_RIDER_VIEW_URL = '/marketplace/teams/rider-view/',
	MARKETPLACE_ALERT_URL = '/marketplace/alert',
	MARKETPLACE_TAGS_URL = '/marketplace/tags',
	MARKETPLACE_MERCHANTS_URL = '/marketplace/merchants',
	MARKETPLACE_MERCHANTS_VIEW_URL = 'marketplace/merchants/edit-merchant/',
	MARKETPLACE_MERCHANTS_BULK_PRODUCT_URL = 'marketplace/merchants/bulk-import-products/',
	MARKETPLACE_MERCHANT_STORES_VIEW_URL = '/stores/store-details/',
	MARKETPLACE_INCIDENT_CONFIG_URL = '/marketplace/incident-config',
	MARKETPLACE_HOME_PAGE_ITEMS_URL = '/marketplace/home-pages',
	MARKETPLACE_HOME_PAGE_ITEMS_DETAIL_URL = '/marketplace/home-pages/home-page-items/',
	MARKETPLACE_MERCHANT_COLLECTION_URL = '/add-collection/',
	MARKETPLACE_MERCHANT_PRODUCT_URL = '/marketplace-product-view/',
	MARKETPLACE_RATING_URL = '/marketplace/rating',
  MARKETPLACE_APP_SETTING_URL = '/marketplace/app-setting',
  MARKETPLACE_CONFIGURATION_URL = '/marketplace/configuration',
  MARKETPLACE_CONFIGURATION_DRIVER_ICON_URL = '/marketplace/configuration/driver-icon',
  MARKETPLACE_REPORTS_URL = '/marketplace/reports',
  MARKETPLACE_CONFIGURATION_SURCHARGE_URL = '/marketplace/configuration/surcharge',

  // Deals & Discounts
  MARKETPLACE_DEALS_AND_DISCOUNTS = '/marketplace/promotions/deals-and-discounts',
  MARKETPLACE_ADD_DEALS_AND_DISCOUNTS = '/marketplace/promotions/deals-and-discounts/add'
}

export enum ClientExpressViewRoutes {
	CLIENT_EXPRESS_VIEW_BRANCHES_URL = '/clientExpressView/branches',
	CLIENT_EXPRESS_VIEW_ORDER_DETAILS_URL = 'clientExpressView/order-details/'
}

export enum CourierRoutes {
	COURIER_MAP_URL = '/courier/map',
	COURIER_AGENCY_URL = '/courier/agency-view',
	COURIER_CUSTOMERS_URL = '/courier/customers',
	COURIER_CUSTOMERS_ADD_URL = '/courier/customers/new-customer',
	COURIER_LOCATION_URL = '/courier/eddresses-view',
	COURIER_RETURN_URL = '/courier/returns',
	COURIER_ZONE_URL = '/courier/zone-list',
	COURIER_MERCHANT_NEW_EDDRESS_URL = '/courier/eddresses-view/merchant-new-eddress/',
	COURIER_CUSTOMER_VIEW_URL = '/courier/customers/customer-view',
	COURIER_ORDER_VIEW_URL = '/courier/customers/merchant-tasks-list/',
	COURIER_WORKER_URL = '/courier/workers',
	COURIER_WORKER_ADD_URL = '/courier/workers/new-worker',
	COURIER_WORKER_VIEW_URL = '/courier/workers/worker-view',
	COURIER_NEW_ZONE_URL = '/courier/workers/new-zone',
	COURIER_EDIT_ZONE_URL = '/courier/workers/edit-zone/',
	COURIER_INCOMING_ORDERS_URL = '/courier/incoming-orders',
	COURIER_NEW_ORDER_URL = '/courier/incoming-orders/client-express-place-order'
}

export enum InterimRoutes {
	INTERIM_ORDERS_URL = '/interim-orders'
}

export enum BillingRoutes {
	BILLING_AGENT_BASE_URL = '/billing/agent/',
	BILLING_AGENT_URL = '/billing/agent',
	BILLING_AGENT_ORDERS_URL = 'billing/agent/orders',
	BILLING_CUSTOMER_URL = '/billing/customer',
	BILLING_CUSTOMER_BASE_URL = '/billing/customer',
	BILLING_ORDERS_URL = '/orders',
	BILLING_INVOICE_ORDERS_URL = '/invoice-orders',
	BILLING_DETAIL_URL = '/detail'
}

export enum DispatcherRoutes {
	DISPATCHER_TEAMS_URL = '/dispatcher/teams'
}

export enum AdminSettingRoutes {
	ADMIN_SETTINGS = '/admin-settings'
}

export enum ClientViewRoutes {
	CLIENT_VIEW_DASHBOARD_URL = '/clientview/dashboard',
	CLIENT_VIEW_PROJECTS_URL = '/clientview/projects',
	CLIENT_VIEW_CUSTOMERS_URL = '/clientview/customers',
	CLIENT_VIEW_ORDERS_URL = 'clientview/orders',
	CLIENT_VIEW_ORDERS_VIEW_URL = 'clientview/orders/view'
}

export enum WareHouseViewRoutes {
	WAREHOUSE_INCOMING_ORDERS_VIEW_URL = '/warehouse/purchase-orders-view',
	WAREHOUSE_ORDERS_EDIT_URL = '/warehouse/purchase-orders-view/edit-purchase-order/',
	WAREHOUSE_NEW_PRODUCT_URL = '/warehouse/purchase-orders-view/product-view/'
}

export enum LastMileRoutes {
	LAST_MILE_WAREHOUSE_OVERVIEW_URL = '/last-mile/orders/warehouse-overview',
	LAST_MILE_TEAMS_OVERVIEW_URL = '/last-mile/teams/teams-overview'
}

export enum AutheticationRoutes {
	LOGIN_URL = 'portal/login',
	SIGNUP_URL = 'portal/signup/'
}

export enum PortalSettingRoutes {
	PORTAL_SETTINGS = '/portal-settings'
}

export const AppRoutesEnum = <const>{
	...MarketPlaceRoutes,
	...ClientExpressViewRoutes,
	...InterimRoutes,
	...BillingRoutes,
	...DispatcherRoutes,
	...AdminSettingRoutes,
	...ClientViewRoutes,
	...LastMileRoutes,
	...AutheticationRoutes,
	...CourierRoutes,
	...WareHouseViewRoutes,
	...PortalSettingRoutes
};
