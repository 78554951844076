import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
export const apiBaseUrl = new InjectionToken<string>('apiBaseUrl');

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	constructor(
		private http: HttpClient,
		@Inject(apiBaseUrl) private _apiBaseUrl: string
	) {}

	get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
		return this.http.get(`${this._apiBaseUrl}${path}`, { params });
	}
	put(path: string, body: Object = {}): Observable<any> {
		return this.http.put(`${this._apiBaseUrl}${path}`, body);
	}
	patch(path: string, body: Object = {}): Observable<any> {
		return this.http.patch(`${this._apiBaseUrl}${path}`, body);
	}
	post(path: string, body: Object = {}): Observable<any> {
		return this.http.post(`${this._apiBaseUrl}${path}`, body);
	}
	delete(path): Observable<any> {
		return this.http.delete(`${this._apiBaseUrl}${path}`);
	}
	bulkPost(path: string, body: Object = {}, header: Object = {}): Observable<any> {
		return this.http.post(`${this._apiBaseUrl}${path}`, body, header);
	}

  bulkDelete(path: string, options: Object = {}): Observable<any> {
    return this.http.delete(`${this._apiBaseUrl}${path}`, options);
  }
	bulkPut(path: string, body: Object = {}, header: Object = {}): Observable<any> {
  	return this.http.put(`${this._apiBaseUrl}${path}`, body, header);
	}
	getBlob(
		path: string,
		params: HttpParams = new HttpParams()
	): Observable<any> {
		return this.http.get(`${this._apiBaseUrl}${path}`, {
			responseType: 'blob'
		});
	}
	getBlobPost(path: string, body: Object = {}): Observable<any> {
		return this.http.post(`${this._apiBaseUrl}${path}`, body, {
			observe: 'response',
			responseType: 'blob'
		});
	}
}
